<template>
  <b-modal id="modalAddEdit" :title="title" no-close-on-backdrop @shown="init">
    <b-form @submit.prevent="save">
      <div class="form-group">
        <label for="name">{{$t('name')}}</label>
        <b-form-input v-model="category.name" v-validate="'required|max:25'" name="name" id="name" :state="validateState('name')" />
        <span class="error-inputs">{{ errors.first('name') }}</span>
      </div>
      <div class="form-group">
        <label for="order">{{$t('order')}}</label>
        <b-form-input v-model="category.order" v-validate="'required|numeric|min_value:1'" name="order" id="order" :state="validateState('order')" type="number" />
        <span class="error-inputs">{{ errors.first('order') }}</span>
      </div>
    </b-form>
    <div slot="modal-footer">
      <b-button @click="$root.$emit('bv::hide::modal', 'modalAddEdit')">{{$t('cancel')}}</b-button>
      <b-button variant="primary" @click="save" class="ml-3">{{$t('save')}}</b-button>
    </div>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    category: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      title: ''
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console'
    ])
  },
  methods: {
    init () {
      if (!this.category.id) {
        this.title = this.$t('create')
      } else {
        this.title = this.$t('edit')
      }
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          if (!this.category.id) {
            const path = `auth/${this.lang}/${this.console}/tournaments/categories/store`
            this.$axios.post(path, this.category).then((response) => {
              this.$store.dispatch('SET_TOURNAMENT_CATEGORIES_ADD', response.data.data)
              this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
              this.$root.$emit('bv::hide::modal', 'modalAddEdit')
            })
          } else {
            const path = `auth/${this.lang}/${this.console}/tournaments/categories/${this.category.id}/update`
            this.$axios.put(path, this.category).then((response) => {
              this.$store.dispatch('SET_TOURNAMENT_CATEGORIES_EDIT', response.data.data)
              this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
              this.$root.$emit('bv::hide::modal', 'modalAddEdit')
            })
          }
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    }
  }
}
</script>
